// import React from "react"
// import {graphql} from "gatsby"
// import Img from "gatsby-image"
// import eachserviceStyles from "../styles/eachservice.module.scss"
// import Layout from "../components/layout"
// // import QuoteForm from "../components/quote/quote.js"


// const MobileCarWashPage = ((props) => {
//   return (
//     <Layout>
//       <div className={eachserviceStyles.maindiv}>
//         {/* left div (service info) */}
//         <div className={eachserviceStyles.servinfodiv}>
//           <div className={eachserviceStyles.headerdiv}>Mobile Car Wash</div>
//           <div className={eachserviceStyles.imagediv}>
//             <Img className={eachserviceStyles.imagetag} fluid={props.data.mobileCarWash.childImageSharp.fluid} alt="domesticCleaning"/>
//           </div>
//           <div className={eachserviceStyles.textdiv}>
//             The beauty of our mobile is that we come to you, 
//             so you won't even have to waste time travelling to and from the clustrered hand car wash cafes. 
//             What's more, it doesn't matter where you are as we can turn up anywhere, whenever you need us. 
//             Visit your mate for a barbecue and when you get back to your car it will be gleaming like new and the inside will be immaculate and smelling fresh as well.
//           </div>
//         </div>
//         {/* right div (quote) */}
//         <div className={eachserviceStyles.quotediv}>
//           <h3>Get A Quote</h3>
//           <form data-netlify="true" className={eachserviceStyles.formdiv}>
//               <div className={eachserviceStyles.namediv}>
//                 <div className={eachserviceStyles.firstnamelabel}><label for="firstName">First Name*</label></div>
//                 <div className={eachserviceStyles.firstnameinput}><input name="firstname" style={{width:'100%',border:'solid 2px black',paddingLeft:'4px'}} type="text" id="firstname" name="firstname" placeholder="your first name..."></input></div>
//                 <div className={eachserviceStyles.lastnamelabel}><label for="lastName">Last Name*</label></div>
//                 <div className={eachserviceStyles.lastnameinput}><input name="lastname" style={{width:'100%',border:'solid 2px black',paddingLeft:'4px'}} type="text" id="lastname" name="lastname" placeholder="your last name..."></input></div>
//               </div>
//               <div className={eachserviceStyles.contactdiv}>
//                 <div className={eachserviceStyles.tellabel}><label for="telnumber">Contact Number*</label></div>
//                 <div className={eachserviceStyles.telinput}><input name="contactNumber" style={{width:'100%',border:'solid 2px black',paddingLeft:'4px'}} type="number" id="telnumber" name="telnumber" placeholder="your contact number..."></input></div>
//                 <div className={eachserviceStyles.emaillabel}><label for="form-email">Email*</label></div>
//                 <div className={eachserviceStyles.emailinput}><input name="email" style={{width:'100%',border:'solid 2px black',paddingLeft:'4px'}} type="email" id="form-email" placeholder="your email address..."/></div>
//               </div>
              
//               <div className={eachserviceStyles.addressdiv}>
//                 <div className={eachserviceStyles.addresslabel}><label for="form-address">Address of Property to be Cleaned*</label></div>
//                 <div className={eachserviceStyles.addressinput}><input name="addressProperty" style={{width:'100%',border:'solid 2px black'}} type="text" id="form-address" placeholder=" your address..."/></div>
//               </div>

//               <div className={eachserviceStyles.selectdiv}>
//                 <div className={eachserviceStyles.selectlabel1}><label for="form-service">Select Cleaning Service*</label></div>
//                 <div className={eachserviceStyles.selectoptions1}>
//                     <select name="serviceOption" style={{width:'100%',border:'solid 2px black'}} id="form-service-option">
//                         <option style={{color:'white',background:'black'}}>Domestic Cleaning</option>
//                         <option style={{color:'white',background:'black'}}>Commercial Cleaning</option>
//                         <option style={{color:'white',background:'black'}}>Overnight Baby Sitting</option>
//                         <option style={{color:'white',background:'black'}}>Mobile Car Wash</option>
//                         <option style={{color:'white',background:'black'}}>Post Construction/Residential</option>
//                         <option style={{color:'white',background:'black'}}>End of Tenancy Service</option>
//                         <option style={{color:'white',background:'black'}}>Carpet, Matress and Couch</option>
//                         <option style={{color:'white',background:'black'}}>Maid Training Service</option>
//                     </select>
//                 </div>
//                 <div className={eachserviceStyles.selectlabel2}><label for="form-hearabout">How did you hear about us?*</label></div>
//                 <div className={eachserviceStyles.selectoptions2}>
//                     <select name="hearAboutOption" style={{width:'100%',border:'solid 2px black'}} id="form-hearabout-option">
//                         <option style={{color:'white',background:'black'}}>Google Search</option>
//                         <option style={{color:'white',background:'black'}}>Housekeeping on Crownwood</option>
//                         <option style={{color:'white',background:'black'}}>Word of Mouth</option>
//                         <option style={{color:'white',background:'black'}}>Branded Vehicles</option>
//                         <option style={{color:'white',background:'black'}}>Leaflets/Flyers</option>
//                         <option style={{color:'white',background:'black'}}>Online Directories</option>
//                     </select>
//                 </div>
//               </div>

//               <div className={eachserviceStyles.commentdiv}>
//                 <div className={eachserviceStyles.commentlabel}><label for="form-address">Comment</label></div>
//                 <div className={eachserviceStyles.commentinput}><textarea name="comment" style={{width:'100%',border:'solid 2px black',paddingLeft:'4px'}} type="text" rows="2" id="form-address" placeholder="leave a comment here..."/></div>
//               </div>

//               <div className={eachserviceStyles.formcheckbox}>
//                   <label for="formcheckbox" class="form-checkbox">
//                   <input type="checkbox" id="formcheckbox" /> I&#x27;ve read the terms and conditions</label>
//               </div>
//               <div className={eachserviceStyles.formbutton}><a type="submit" className={eachserviceStyles.formsubmitbut}>SUBMIT</a></div>
//           </form>
//         </div>
//       </div>
//     </Layout>
//   )
// }
// )

// export default MobileCarWashPage

// export const pageQuery = graphql`
//   query {
//     mobileCarWash: file(relativePath: {eq: "MobileCarWash.jpg"}) {
//       childImageSharp {
//         fluid(maxWidth: 1000) {
//           ...GatsbyImageSharpFluid
//         }
//       }
//     }
//   }
// `




import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import eachserviceStyles from "../styles/eachservice.module.scss"
import Layout from "../components/layout"


const MobileCarWashPage = ((props) => {
  return (
    <Layout>
      <div className={eachserviceStyles.maindiv}>
        <div className={eachserviceStyles.headerdiv}>Mobile Car Wash</div>
        <div className={eachserviceStyles.imagediv}>
          <Img className={eachserviceStyles.imagetag} fluid={props.data.mobileCarWash.childImageSharp.fluid} alt="domesticCleaning"/>
        </div>
        <div className={eachserviceStyles.textdiv}>
            The beauty of our mobile is that we come to you, 
            so you won't even have to waste time travelling to and from the clustrered hand car wash cafes. 
            What's more, it doesn't matter where you are as we can turn up anywhere, whenever you need us. 
            Visit your mate for a barbecue and when you get back to your car it will be gleaming like new and the inside will be immaculate and smelling fresh as well.
        </div>
      </div>
    </Layout>
  )
}
)

export default MobileCarWashPage

export const pageQuery = graphql`
  query {
    mobileCarWash: file(relativePath: {eq: "MobileCarWash.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`